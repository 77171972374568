(function () {
  'use strict';

  angular
    .module('neo.home.sancions')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.sancions', {
        url: '/sancionscomite/{dataini}/{datafi}',
        templateUrl: 'home/sancions/sancions.tpl.html',
        
        controller: 'sancionsCtrl',
        controllerAs: 'vm',
        resolve: {
          sancionscomite: function (sancionscomite,$stateParams) {
            return sancionscomite.query({dataini :  $stateParams.dataini,datafi:$stateParams.datafi}).$promise;
          }
        }
      });
  }
}());
